﻿@import 'webcore-ux/style/Colors';

// Height of the app stripe
$visual-box-header-height: 24px;

.visual-box {
    border: 1px solid $color-grey-186;
}

.visual-box-header {
    height: $visual-box-header-height;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 4px !important;
    background-color: $color-grey-219;
}

.visual-box-caption {
    line-height: $visual-box-header-height;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
