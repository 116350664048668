@import 'webcore-ux/style/Colors';
@import 'common/Common.scss';
@import './hitachiLogos.scss';


.jemi-stripe {
    display: flex;
    height: 35px;
    border-top: $app-stripe-top-border-height solid #eb0023;
    border-bottom: $app-stripe-bottom-border-height solid $color-grey-210;
    color: $color-grey-31;
    padding: 10px 0px;

    &:after {
        position: relative;
        left: -40px;
        top: 0%;
        padding-left: 10px;
        border-left: 2px solid #d2d2d2;
        content: "Energy Market Intelligence – Japan";
        font-size: 18px;
        line-height: 2;
        vertical-align: middle;
    }

    .jemi-brand {
        margin-left: 10px;
        width: 267px;
        height: 34px;
        background: $hitachi-energy no-repeat left 2px /80%;
    }
}
