@import 'common/Common.scss';

//
// General button groups
//
.de-filter-button-group {
    flex-wrap: wrap;
}

.de-year-picker button.MuiToggleButton-root {
    font-family: $de-font-family;
    margin-left: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 0;
    width: 64.5px;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.de-year-picker div.wcux-toggle-button-content {
    margin-left: 0;
    margin-right: 0;
}

.de-year-picker button.MuiToggleButton-root:nth-child(n + 6) {
    margin-top: -1px !important;
}

.de-year-picker button.MuiToggleButton-root:not(:nth-child(5n - 4)) {
    margin-left: -1px !important;
}