@import 'webcore-ux/style/Colors';
@import '../common/Common.scss';
@import '../common/VisualBox.scss';

.spatial-awareness {
    width: 100%;
}

.spatial-awareness-map {
    width: 100%;
    height: $app-tab-contents-height;
    //height: calc(#{$app-tab-contents-height} - #{$visual-box-header-height});
}
.spatial-awareness-map *:focus {
    outline: none;
}

.spatial-awareness-map-buttons {
    position: relative;
}

.spatial-awareness-search-button {
    position: absolute;
    top: 8px;
    right: 122px;
    z-index: 1;
}
.spatial-awareness-search-button button {
    min-width: 36px !important;
    width: 36px;
    height: 32px !important
}
.spatial-awareness-search-button .wcux-button-root svg {
    margin-top: 6px;
}

.spatial-awareness-layers-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
}
.spatial-awareness-layers-button button {
    min-width: 36px !important;
    width: 36px;
    height: 32px !important
}
.spatial-awareness-layers-button .wcux-button-primary svg {
    margin-top: 6px;
}

.spatial-awareness-animations-button {
    position: absolute;
    top: 8px;
    right: 46px;
    z-index: 1;
}
.spatial-awareness-animations-button button {
    min-width: 36px !important;
    width: 36px;
    height: 32px !important
}
.spatial-awareness-animations-button .wcux-button-primary svg {
    margin-top: 6px;
}

// Legend button
.spatial-awareness-legend-button {
    position: absolute;
    top: 8px;
    right: 84px;
    z-index: 1;
}
.spatial-awareness-legend-button button {
    min-width: 36px !important;
    width: 36px;
    height: 32px !important
}
.spatial-awareness-legend-button .wcux-toggle-button-root svg {
    margin-top: 6px;
}

// Disable "Save image as" option for layers
.spatial-awareness-map div.ol-layer > canvas {
    pointer-events: none;
}
