@import 'webcore-ux/style/Colors';

// Top-level container for statement of income charts
.statement-of-income-charts {
    // Use flex here so that the content can grow.
    // If the content can't grow to wider than the available area,
    // then the first chart with the axis labels won't "stick" properly.
    display: flex;
    height: 100%;
    overflow: auto;
}

// This is an inner container that's allowed to grow as more charts
// are added
.statement-of-income-charts-inner {
    // Grow content to the right
    display: flex;
    flex-flow: row;
    flex: 1 0 auto;

    height: 100%;
}

// This is the top-level container for an individual chart
.statement-of-income-chart {
    // This will also be flex, and is intended grow down
    // with the title plus the actual chart
    display: flex;
    flex-flow: column;

    // This prevents the chart from growing/shrinking 
    // as more charts are added to the parent
    flex: 0 0 auto;
    min-width: 0;
    min-height: 345px;

    // Generally, charts will have a left margin and a bottom margin
    margin-left: 8px;
    padding-bottom: 4px;

    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

// First chart is a "dummy" chart that's shown only to provide bar labels
.statement-of-income-chart:first-child {
    // Make it sticky to the left so it won't scroll out of view
    position: sticky;
    left: 0;
    // Override left margin - we don't need a left margin on this chart
    margin-left: 0;

    background-color: #fff;
    // Keep it on top of the other charts as the user scrolls
    z-index: 1;
}

// The second chart is the first "real" chart
.statement-of-income-chart:nth-child(2) {
    // We don't need a left margin here, either
    margin-left: 0;
}

// The last chart
.statement-of-income-chart:last-child {
    margin-right: 4px;
}

// This styles the chart title element
.statement-of-income-chart > div:first-child {
    padding-top: 2px;
    padding-bottom: 2px;
    flex: 0 1 auto;
    overflow: hidden;
    white-space: nowrap;

    text-align: center;
}

// This styles the container for the Recharts responsive chart
.statement-of-income-chart > div:nth-child(2) {
    // Allow the chart to grow/shrink in height based on the 
    // title size
    flex: 1 1 auto;
    min-height: 0;
    
    // Add a border
    border: 1px solid $color-grey-210;
}

// This styles the container for the first "dummy" chart
.statement-of-income-chart:first-child > div:nth-child(2) {
    // Override the border.  Make it the background color
    // so it affects the size of the content, but isn't 
    // visible to the user.
    border: 1px solid #fff;
}
