@import 'webcore-ux/style/Colors';
@import 'common/Common.scss';

$expander-diameter: 24px;
$expander-diameter-full: 26px;  // Diameter with border
$widthAnimationDuration: 0;

.vertical-tab-nav-container {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 100%;
}

.vertical-tab-nav {
    // Flex basis is set as an inline style based on expanded state
    margin-right: 5px;
    border-right: 1px solid $color-grey-235;
    transition: flex-basis $widthAnimationDuration;
}

.vertical-tab-nav-container .tab-panels {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    height: 100%;
}

.vertical-tab-nav button.MuiButtonBase-root {
    color: $color-grey-160;
    padding: 12px;
    min-width: 100%;
    width: 100%;
}

.vertical-tab-nav button.Mui-selected {
    color: $color-grey-31;
}

.vertical-tab-nav .MuiTabs-indicator {
    background-color: $app-primary-color;
}

.vertical-tab-nav-container .tab-panels div[role="tabpanel"] {
    height: 100%;
}

.vertical-tab-nav-container .expander {
    position: absolute;
    top: calc(50% - #{$expander-diameter-full});
    // left is set in as an inline style based on expanded state
    width: $expander-diameter;
    height: $expander-diameter;
    border: 1px solid $color-grey-235;
    border-radius: 50%;
    background-color: #fff;
    z-index: 4;  // One more than the WebCore UX grid's header value of 3...

    color: $color-grey-235;
    transition: left $widthAnimationDuration;

    .nav-icon-expanded {
        transform: rotate(-90deg);
    }
    .nav-icon-collapsed {
        margin-left: 1px;
        transform: rotate(90deg);
    }
}

.vertical-tab-nav-container .expander:hover {
    border: 1px solid $color-grey-200;
    color: $color-grey-31;
}

:export {
    expanderDiameterFull: $expander-diameter-full;
}