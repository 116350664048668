.supply-and-demand-chart .recharts-scatter-symbol {
    display: none !important;
}

.supply-and-demand-chart .recharts-tooltip-item {
    margin: 0;
    padding: 1px !important;
}

.supply-and-demand-chart .recharts-tooltip-item-name {
    display: inline-block;
}

.supply-and-demand-chart .recharts-tooltip-item-value {
    display: inline-block;
    float: right;
    min-width: 80px;
    text-align: right;
}

