@import '../common/Common.scss';
@import '../common/VisualBox.scss';

.supply-and-demand {
    width: 100%;
}

.supply-and-demand-chart-title {
    position: absolute;
    top: 8px;
    left: 152px;
    width: calc(100% - 240px);
    text-align: center;
    word-break: keep-all;
}

.supply-and-demand .supply-and-demand-chart-container {
    position: relative;
    width: 100%;
    height: $app-tab-contents-height
}

.supply-and-demand-chart-container .recharts-scatter-symbol {
    display: none !important;
}

.supply-and-demand-chart-container .recharts-tooltip-item {
    margin: 0;
    padding: 1px !important;
}

.supply-and-demand-chart-container .recharts-tooltip-item-name {
    display: inline-block;
}

.supply-and-demand-chart-container .recharts-tooltip-item-value {
    display: inline-block;
    float: right;
    min-width: 100px;
    text-align: right;
}

.supply-and-demand-filters {
    // Give the Dropdown room to open without scrolling the filter panel
    min-height: 515px;
}

.supply-and-demand-filters > div.visual-box > div > div.button-group-filter > div.wcux-checkbox {
    margin-top: 10px;
    margin-left: -2px;
}

.supply-and-demand-tooltip-content {
    display: flex;
}

.supply-and-demand-tooltip-visual {
    // Grow content to the right
    display: flex;
    flex-flow: row;
    flex: 1 0 auto;
}