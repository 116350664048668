@import 'webcore-ux/style/Colors';
@import '../common/Common.scss';
@import '../common/VisualBox.scss';

$height: calc(#{$app-tab-contents-height} - #{$visual-box-header-height});

.price-forecast {
    position: relative;
    width: 100%;

    nav {
        width: 100%;

        button.MuiButtonBase-root {
            color: $color-grey-160;
            padding: 12px;
        }

        button.Mui-selected {
            color: $color-grey-31;
        }

        span.MuiTabs-indicator {
            background-color: $app-primary-color;
        }
    }

    article {
        width: 100%;
        height: $height;

        h1 {
            position: absolute;
            display: block;
            width: 92%;
            margin: 10px 0px 0px 0px;
            padding: 0px 2%;
            font-size: 14px;
            font-weight: 400;
            word-break: keep-all;
            text-align: center;
        }
    }

    > div {
        height: $app-tab-contents-height;
    }
}

.price-forecast-container {
    height: 100%;

    .visual-box:nth-child(n + 2) {
        margin-top: 5px;
    }

    .visual-box-header + div {
        padding: 10px;
    }

    .wcux-dropdown-root:nth-child(n + 2) {
        margin-top: 5px;
    }

    .de-date-picker {
        width: calc(50% - 10px);
        min-width: 149px;
    }

    .granularity-buttons {
        display: block;
        width: 97%;
        margin: 10px auto 15px auto;
    }
}

.price-forecast-visual-box-content {
    height: 100%;
}
