@import 'webcore-ux/style/Colors';
@import '../common/Common.scss';
@import '../common/VisualBox.scss';

.company-financials {
    width: 100%;
}

.company-financials .company-financials-charts {
    position: relative;
    width: 100%;
    height: $app-tab-contents-height;
}

.company-financials-filters {
    display: block;
    // Give the Dropdown room to open without scrolling the filter panel
    min-height: 510px;
}

////////////////////////////////////////////////////////////////////////////////
// These styles support chart "show data" / "hide data" buttons
////////////////////////////////////////////////////////////////////////////////
.company-financials-data-buttons {
    position: absolute;
    top: 8px;
    right: 54px;
    z-index: 1;
}
.company-financials-data-buttons .wcux-button-root {
    width: 36px;
}
.company-financials-data-buttons .wcux-button-root button {
    max-width: 36px;
}
.company-financials-data-buttons .wcux-button-root svg {
    margin-top: 6px;
}
.company-financials-data-buttons .wcux-toggle-button-root {
    width: 36px;
}
.company-financials-data-buttons .wcux-toggle-button-root button {
    max-width: 36px;
}
.company-financials-data-buttons .wcux-toggle-button-root svg {
    margin-top: 6px;
}

////////////////////////////////////////////////////////////////////////////////
// These styles support the chart and title
////////////////////////////////////////////////////////////////////////////////
.company-financials-chart-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.company-financials-chart-title {
    flex: 0 1 auto;
    padding: 10px 90px 0 90px;
    width: calc(100% - 180px);
    text-align: center;
    word-break: keep-all;
}

.company-financials-chart {
    flex: 1 1 auto;
    min-height: 0;
}

.company-financials-chart-tooltip-container { 
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $color-grey-210;
}

.company-financials-grid-container {
    height: calc(100% - 10px);
    margin-top: 8px;
}