@import 'webcore-ux/style/Colors';
@import '../../common/Common.scss';

$pagination-container-height: 32px;

.popup-load-indicator-container {
    width: 100%;
    height: 100px;
    padding-top: calc(50px - 17px);
}

.feature-popup-content-container {
    padding: $feature-popup-content-padding;
    width: calc(#{$feature-popup-content-width} + 2 * #{$feature-popup-content-padding});
    height: calc(100vh - 36px - #{$pagination-container-height} - #{$feature-popup-content-padding});
    overflow-x: hidden;
    overflow-y: auto;
}

div.feature-popup-footer {
    display: flex;
    justify-content: center;
    height: $pagination-container-height;
}

div.pagination-container {
}

span.feature-popup-title {
    font-weight: bold;
}

span.feature-popup-subtitle {
}

span.feature-popup-property-name {
    display: inline-block;
    float: left;
    width: 150px;
    font-weight: bold;
}

span.feature-popup-property-name-wide {
    width: 250px;
}

span.feature-popup-property-value {
    display: inline-block;
    float: left;
    /* Start with the 510px width.  Remove margin/padding, width of property name, then leave room for scroll bar */
    max-width: calc(510px - 48px - 150px - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

span.feature-popup-property-value-narrow {
    /* Start with the 510px width.  Remove margin/padding, width of property name, then leave room for scroll bar */
    max-width: calc(510px - 48px - 250px - 20px);
}

/* Clear float at each <br> in popups to prevent unexpected indentations */
span.feature-popup-property-value + br {
    clear: both;
}
span.feature-popup-property-value-narrow + br {
    clear: both;
}

/* Tree node styles */
span.tree-title-field-name {
    font-weight: bold;
}

/* Pie chart styles */
.power-plant-chart-legend-container {
    width: $feature-popup-content-width;
    text-align: center;
}

.power-plant-chart-legend {
    display: inline-block;
}

/* Fuel And Status Chart Styles */
.fuel-and-status-chart-tooltip-container { 
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $color-grey-210;
}

span.property-tree-name {
    width: auto;
}

.property-tree-icon {
    display: block;
    margin-left: 5px;
    float: left;
    height: 18px;
    max-height: 18px;
}
    .property-tree-icon:hover {
        cursor: pointer;
    }
.property-tree-icon > svg {
    height: 18px;
    max-height: 18px;
}