@import 'webcore-ux/style/Colors';

$layer-chooser-content-width: 300px;

.layerchooser-layers {
    padding: 15px;
    width: $layer-chooser-content-width;

    border-top: 1px solid $color-grey-235;
}

:export {
    layerChooserContentWidth: $layer-chooser-content-width;
}