@import './Common.scss';

ul.MuiTreeView-root {
    margin-left: -3px !important;
}

ul.MuiTreeView-root .MuiTreeItem-label {
    font-family: $de-font-family;
    font-size: 14px;
}

ul.MuiTreeView-root ul {
    padding-left: 21px !important;
}

.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: rgba(0, 0, 0, 0.0) !important;
}

.MuiTreeItem-label:hover {
    background-color: rgba(0, 0, 0, 0.0) !important;
}