@import 'webcore-ux/style/Colors';
@import '../../common/Common.scss';

// Absolute-positioned bottom of legend
$legend-bottom: 72px;
$legend-content-width: 300px;

.ev-legend {
    margin: 0;
    padding: 5px;
    position: absolute;
    right: 15px;
    bottom: $legend-bottom;
    z-index: 1;
    width: $legend-content-width;
    max-height: calc(
        #{$app-tab-contents-height} - #{$legend-bottom} - 53px
    );
    background-color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
}

.ev-legend-divider {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 1px;
    background-color: $color-grey-235;
}

span.ev-legend-layer-name {
    font-weight: bold;
}

span.ev-legend-theme-variable {

}

span.ev-legend-symbol {
    display: inline-block;
    float: left;
    margin-left: 6px;
    width: 20px;
}

span.wind-arrow-0 {
    margin-left: 7px;
    width: 20px;
}

span.wind-arrow-10 {
    margin-left: 2px;
    width: 25px;
}

span.wind-arrow-20 {
    margin-left: -3px;
    width: 30px;
}

span.ev-legend-theme-value {
    display: inline-block;
    float: left;
    line-height: 21px;

    max-width: calc(#{$legend-content-width} - 10px - 20px - 6px - 18px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

span.ev-legend-theme-value + br {
    clear: both;
}

span.wind-value-0 {
    max-width: calc(#{$legend-content-width} - 10px - 30px - 6px - 18px);
    line-height: 18px;
}

span.wind-value-10 {
    max-width: calc(#{$legend-content-width} - 10px - 30px - 6px - 18px);
    line-height: 22px;
}

span.wind-value-20 {
    max-width: calc(#{$legend-content-width} - 10px - 30px - 6px - 18px);
    line-height: 30px;
}

//
// Legend gradients
//
$gradient-end-width: 49px;
$gradient-end-border-width: 1px;

div.ev-legend-pre-gradient {
    clear: both;
    float: left;
    border-right: $gradient-end-border-width solid rgba(31, 31, 31, 0.3);
    width: $gradient-end-width;
    height: 20px;
}

div.ev-legend-gradient {
    width: calc(#{$legend-content-width} - 2 * (#{$gradient-end-width} + #{$gradient-end-border-width}));
    height: 20px;
    float: left;
    margin-right: calc(-1 * (#{$gradient-end-width} + #{$gradient-end-border-width}));
}

div.ev-legend-post-gradient {
    border-left: $gradient-end-border-width solid rgba(31, 31, 31, 0.3);
    width: $gradient-end-width;
    height: 20px;
    float: right;
}

div.ev-legend-gradient-labels {
    width: 100%;
    height: 16px;
}

span.ev-legend-gradient-label-low {
    display: block;
    float: left;
    font-size: 11px;
}

span.ev-legend-gradient-label-5th {
    display: block;
    clear: both;
    float: left;
    margin-left: 50px;
    font-size: 11px;
}

span.ev-legend-gradient-label-95th {
    display: block;
    margin-right: calc(#{$gradient-end-width} + #{$gradient-end-border-width});
    float: right;
    font-size: 11px;
}

span.ev-legend-gradient-label-high {
    display: block;
    float: right;
    font-size: 11px;
}