@import 'webcore-ux/style/Colors';

.lng-market-options {
    position: absolute;
    top: 8px;
    right: 54px;
    z-index: 1;
}

.lng-market-options .wcux-toggle-button {
    width: 36px;
}

.lng-market-options .wcux-toggle-button svg {
    margin-top: 6px;
}
