@import 'webcore-ux/style/Colors';
@import '../common/Common.scss';
@import '../common/VisualBox.scss';

.weather {
    width: 100%;
}

.weather .weather-charts {
    position: relative;
    width: 100%;
    height: $app-tab-contents-height;
}

////////////////////////////////////////////////////////////////////////////////
// These styles move tooltip items closer together to help fit up to 20
// series' tooltip values onto the screen
////////////////////////////////////////////////////////////////////////////////
.weather .weather-charts .recharts-tooltip-label {
    margin: 0;
    padding: 0 0 3px 0 !important;
}

.weather .weather-charts .recharts-tooltip-item {
    margin: 0;
    padding: 1px 0 1px 0 !important;
}

.weather-filters {
    display: block;
    // Give the Dropdown room to open without scrolling the filter panel
    min-height: 510px;
}

////////////////////////////////////////////////////////////////////////////////
// These styles support the chart and title
////////////////////////////////////////////////////////////////////////////////
.weather-chart-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.weather-chart-title {
    flex: 0 1 auto;
    padding: 10px 90px 0 90px;
    width: calc(100% - 180px);
    text-align: center;
    word-break: keep-all;
}

.weather-chart {
    flex: 1 1 auto;
    min-height: 0;
}

.weather-chart-tooltip-container { 
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $color-grey-210;
}

////////////////////////////////////////////////////////////////////////////////
// Weather chart options
////////////////////////////////////////////////////////////////////////////////
.weather-chart-options {
    position: absolute;
    z-index: 1;
  
    .wcux-toggle-button {
        width: 36px;
    }

    .wcux-toggle-button svg {
        margin-top: 6px;
    }
}

.weather-chart-show-direction {
    top: 8px;
    right: 54px;
}
