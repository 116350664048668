@import 'webcore-ux/style/Colors';
@import 'common/Common.scss';

.pinnable-settings-scrollable {
    overflow-y: auto;
    max-height: 100%;
}

.pinnable-settings-container {
    padding: 10px;
    border-top: 1px solid $color-grey-235;
}
