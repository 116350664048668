@import 'webcore-ux/style/Colors';

$search-content-width: 475px;

.search-content {
    padding: 15px;
    width: $search-content-width;

    border-top: 1px solid $color-grey-235;

    // Need to override wcux-slide-panel setting so search box renders correctly
    box-sizing: content-box !important;
}
