@import 'webcore-ux/style/Colors';

.forward-market-options {
    position: absolute;
    z-index: 1;
}

.forward-market-options .wcux-toggle-button {
    width: 36px;
}

.forward-market-options .wcux-toggle-button svg {
    margin-top: 6px;
}

.forward-market-range-slider {
    position: relative;
    margin-right: 80px;
    padding-left: 100px;
    bottom: 30px;
}

.forward-market-theme-type {
    top: 8px;
    right: 54px;
}

.forward-market-color-by {
    top: 8px;
    right: 99px;
}

.forward-market-chart .recharts-legend-item {
    margin-left: 20px;
    padding-right: 10px;
}

span.forward-market-legend-group {
    margin-left: -19px;
    font-weight: bold;
}

//
// Tooltip styles
//
.forward-market-tooltip li.recharts-tooltip-item {
    padding-top: 1px;
    padding-bottom: 1px;
}
.forward-market-tooltip li.recharts-tooltip-item > span {
    display: inline-block;
}

.forward-market-tooltip-detailed li.recharts-tooltip-item > span:nth-child(1) {
    width: 190px;
}
.forward-market-tooltip-summary li.recharts-tooltip-item > span:nth-child(1) {
    width: 100px;
}
.forward-market-tooltip li.recharts-tooltip-item > span:nth-child(n + 2) {
    text-align: right;
}
.forward-market-tooltip li.recharts-tooltip-item > span:nth-child(2) {
    width: 60px;
}
.forward-market-tooltip li.recharts-tooltip-item > span:nth-child(3) {
    width: 105px;
}
.forward-market-tooltip li.recharts-tooltip-item > span:nth-child(4) {
    width: 105px;
}
.forward-market-tooltip li.recharts-tooltip-item > span:nth-child(5) {
    width: 105px;
}