@import 'webcore-ux/style/Colors';

$animation-chooser-content-width: 300px;

.animation-radio {
    display: inline-block;
    margin: 1px 0 1px 0;
    height: 18px;
}

.animation-radio input[type="radio"] {
    display: inline-block;
    line-height: 18px;
    height: 18px;
    vertical-align: middle;
}

.animation-radio span {
    display: inline-block;
    padding-top: 1px;
    height: 18px;
    line-height: 18px;
    vertical-align: middle;
}

.animation-chooser-animations {
    padding: 15px;
    width: $animation-chooser-content-width;

    border-top: 1px solid $color-grey-235;
}

:export {
    animationChooserContentWidth: $animation-chooser-content-width;
}