@import 'common/Common.scss';

//
// Apply DE-like styles to the DatePicker and underlying
// MuiPickers markup
//
.de-date-picker * {
    font-family: $de-font-family !important;
    font-size: 14px !important;
}

.de-date-picker-popover * {
    font-family: $de-font-family !important;
}

.de-date-picker-popover .MuiPickersBasePicker-pickerView * {
    font-size: 14px !important;
}

.MuiPickersDay-daySelected {
    background-color: $app-primary-color !important;
}

.MuiPickersToolbar-toolbar {
    background-color: $app-primary-color !important;
}

.MuiInput-underline:after {
    background-color: $app-primary-color !important;
    border-bottom-color: $app-primary-color !important;
}

.MuiTypography-colorPrimary {
    color: $app-primary-color !important;
}

.MuiPickersCalendarHeader-transitionContainer p {
    font-weight: bold !important;
}