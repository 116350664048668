@import 'webcore-ux/style/Colors';

.spot-market-options {
    position: absolute;
    top: 8px;
    right: 54px;
    z-index: 1;
}

.spot-market-options .wcux-toggle-button {
    width: 36px;
}

.spot-market-options .wcux-toggle-button svg {
    margin-top: 6px;
}


.spot-market-range-slider {
    position: relative;
    padding-left: 100px;
    bottom: 30px;
}