@import 'webcore-ux/style/Colors';

// Height of the app stripe
$app-stripe-height: 60px;
// Height of stripe's top border
$app-stripe-top-border-height: 4px;
// Height of stripe's bottom border
$app-stripe-bottom-border-height: 1px;
// Usable height inside stripe
$app-stripe-content-height: $app-stripe-height - $app-stripe-top-border-height - $app-stripe-bottom-border-height;
// Height of the border between the bottom of the stripe and the tabs
$app-tabs-border-height: 5px;
// Height of the tab buttons ONLY
$app-tabs-button-height: 36.5px;
// Height of the tab buttons PLUS padding built in by TabContainer
$app-tabs-height: 36px;
// Height of the app footer
$app-footer-height: 24px;
$app-footer-height-with-border: $app-footer-height + 1;
// Height of tab contents
$app-tab-contents-padding: 5px;
$app-tab-contents-height: calc(
    100vh - #{$app-stripe-height} - #{$app-tabs-border-height} - #{$app-tabs-height} - #{$app-footer-height-with-border} - 2 * #{$app-tab-contents-padding} - 2px
);

// App font family
$de-font-family: ABBvoice, 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', Arial, Helvetica, sans-serif;

// Feature popup attributes
$feature-popup-content-width: 500px;
$feature-popup-content-padding: 10px;

// UI primary color
$app-primary-color: $color-blue-60;
$app-text-color: $color-grey-31;

// Additional ABB colors
// Blues
$abb-blue-10: #DEF4FA;
$abb-blue-20: #B3E6FF;
$abb-blue-30: #6CD5FF;
$abb-blue-40: #2FC8F7;
$abb-blue-50: #00ACEC;
$abb-blue-60: #1B7AC2;
$abb-blue-70: #005CA3;
$abb-blue-80: #004480;
$abb-blue-90: #00284A;
$abb-blue-100: #031324;
// Greens
$abb-green-10: #E2F7DF;
$abb-green-20: #BFEDBB;
$abb-green-30: #98D694;
$abb-green-40: #75BA70;
$abb-green-50: #51A34B;
$abb-green-60: #477A43;
$abb-green-70: #1E6119;
$abb-green-80: #1B4718;
$abb-green-90: #10290E;
$abb-green-100: #011A00;
// Golds
$abb-gold-10: #FFEDDB;
$abb-gold-20: #FFD3A8;
$abb-gold-30: #F2AC66;
$abb-gold-40: #D98F45;
$abb-gold-50: #C27121;
$abb-gold-60: #A65503;
$abb-gold-70: #753B00;
$abb-gold-80: #542C04;
$abb-gold-90: #331B02;
$abb-gold-100: #1A0E00;

:export {
    featurePopupContentWidth: $feature-popup-content-width;
    appPrimaryColor: $app-primary-color;
    appTextColor: $app-text-color;

    abbBlue10: $abb-blue-10;
    abbBlue20: $abb-blue-20;
    abbBlue30: $abb-blue-30;
    abbBlue40: $abb-blue-40;
    abbBlue50: $abb-blue-50;
    abbBlue60: $abb-blue-60;
    abbBlue70: $abb-blue-70;
    abbBlue80: $abb-blue-80;
    abbBlue90: $abb-blue-90;
    abbBlue100: $abb-blue-100;

    abbGreen10: $abb-green-10;
    abbGreen20: $abb-green-20;
    abbGreen30: $abb-green-30;
    abbGreen40: $abb-green-40;
    abbGreen50: $abb-green-50;
    abbGreen60: $abb-green-60;
    abbGreen70: $abb-green-70;
    abbGreen80: $abb-green-80;
    abbGreen90: $abb-green-90;
    abbGreen100: $abb-green-100;

    abbGold10: $abb-gold-10;
    abbGold20: $abb-gold-20;
    abbGold30: $abb-gold-30;
    abbGold40: $abb-gold-40;
    abbGold50: $abb-gold-50;
    abbGold60: $abb-gold-60;
    abbGold70: $abb-gold-70;
    abbGold80: $abb-gold-80;
    abbGold90: $abb-gold-90;
    abbGold100: $abb-gold-100;
}