.popout-chart {
    margin-top: 10px;
}

.popout-chart .recharts-tooltip-label {
    font-weight: bold !important;
    margin-bottom: 4px !important;
}

.popout-chart .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    line-height: 14px;
}

.popout-chart .recharts-legend-item-text {
    font-size: 12px;
    line-height: 14px;
}

.popout-chart .recharts-default-tooltip {
    font-size: 12px;
    line-height: 14px;
}