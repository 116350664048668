@import 'webcore-ux/style/Colors';
@import 'common/Common.scss';

////////////////////////////////////////////////////////////////////////////////
// The styles in this document primarly override react-select styles to
// make it look consistent with WebCore UX.
//
// The following is the HTML structure with typical class names as a reference
// for what's affected by the styles below.
////////////////////////////////////////////////////////////////////////////////

// (A) Top-level element with our "de-" class applied
// <div class="de-multi-select-dropdown css-2b097c-container">
//   // (B) This element provides the visual border of the dropdown - border-radius, color, etc.
//   <div class=" css-1pahdxg-control">
//     // (C) This is the input area - where placeholders and selected items go
//     <div class=" css-1hwfws3">
//       // (D) Here's our multi-item placeholder with our own class
//       <div class="multiple-selected-items-placeholder">Multiple Selected Values...</div>
//       <div class="css-1g6gooi">
//         ...
//       </div>
//
// OR
// 
//       // (E) Single-selected value item (gray oval)
//       <div class="css-1rhbuit-multiValue">
//         // (F) Selected item text
//         <div class="css-12jo7m5">Index Price All Hours</div>
//         // (G) Container for remove-item "x" glyph
//         <div class="css-xb97g8">
//           <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r">
//             <path
//               ...
//             </path>
//           </svg>
//         </div>
//       </div>
//
//     </div>
//     // Parent container for all action buttons (clear, dropdown)
//     <div class=" css-1wy0on6">
//       // (H) container for an action button, e.g. clear all
//       <div aria-hidden="true" class=" css-1gtu0rj-indicatorContainer">
//         <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r">
//           <path
//             ...
//           </path>
//         </svg>
//       </div>
//       <span class=" css-1okebmr-indicatorSeparator"></span>
//       <div aria-hidden="true" class=" css-1gtu0rj-indicatorContainer">
//         <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r">
//           <path
//             ...
//           </path>
//         </svg>
//       </div>
//     </div>
//   </div>
//
//   // Now we're getting into the actual menu of options
//   <div class=" css-26l3qy-menu">
//     // Another container level
//     <div class=" css-11unzgr">
//       // Each of these is a container for a group
//       <div class=" css-1s9izoc">
//         // Each of these is the actual group menu item
//         <div class=" css-18ng2q5-group" id="react-select-2-group-0-heading">Index Prices</div>
//         <div>
//           // (I) Each of these is an option that can be selected
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-0" tabindex="-1">
//             // (J) Checkbox controls
//             <div class="wcux-checkbox multi-select-checkbox">
//               ...
//             </div>
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-1" tabindex="-1">
//           </div>
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-2" tabindex="-1">
//           </div>
//         </div>
//       </div>
//       <div class=" css-1s9izoc">
//       </div>
//     </div>
//   </div>
// </div>

// (A) Top-level element
.de-multi-select-dropdown {
    display: block;
}

// (B) Just inside dropdown
.de-multi-select-dropdown > div:first-of-type {
    border-radius: 0px;
    min-height: 32px;
    height: 34px;
}
.de-multi-select-dropdown > div:first-of-type:hover {
    background-color: $color-grey-235;
}

// (B) Same as above, but when selected there is an additional span as the first child
.de-multi-select-dropdown > span[aria-live="polite"] + div {
    border-color: $app-primary-color;
    box-shadow: none;
}

// (C) Multivalue container / input area
.de-multi-select-dropdown > div:first-of-type > div:first-of-type {
    // Don't let content change size of input area
    max-height: 32px;
    overflow: hidden;
}

// (D) Multiple-selected items placeholder
.de-multi-select-dropdown div.multiple-selected-items-placeholder {
    display: block;
    // This matches WebCore Dropdown placeholder style, but isn't an SCSS-defined color
    color: #808080;
}

// (E) Single selected value (grey oval)
.de-multi-select-dropdown > div:first-of-type > div:first-of-type > div[class$="-multiValue"] {
    border-radius: 12px;
    background-color: $color-grey-219;
    height: 22px;
    display: flex;
    padding-left: 6px;
    margin: 2px 8px 2px 0;
}

// (F) Text for selected item in edit part of dropdown
.de-multi-select-dropdown > div:first-of-type > div:first-of-type > div[class$="-multiValue"] > div:first-of-type {
    margin-right: 4px;
    padding: 2px 3px 4px 6px;
}

// (G) The "x" for "remove-this-item" in selected item
.de-multi-select-dropdown > div:first-of-type > div:first-of-type > div[class$="-multiValue"] > div:nth-of-type(2) {
    margin-top: 3px;
    margin-right: 4px;
    padding: 1px;
    height: 16px;
}
.de-multi-select-dropdown > div:first-of-type > div:first-of-type > div[class$="-multiValue"] > div:nth-of-type(2):hover {
    background-color: $color-grey-219;
    color: $color-grey-31;
}

// All button svg elements
.de-multi-select-dropdown svg {
    width: 16px;
    height: 16px;
}

// (H) Container for action buttons, e.g. clear all, dropdown
.de-multi-select-dropdown > div:first-of-type > div:nth-of-type(2) > div[class$="-indicatorContainer"] {
    padding-top: 8px;
    padding-right: 4px;
    padding-bottom: 8px;
    padding-left: 4px;
}

// (I) Menu options - selected and hovered
.de-multi-select-dropdown div[class$="-menu"] div[class$="-option"] {
    background-color: #fff;;
}
.de-multi-select-dropdown div[class$="-menu"] div[class$="-option"]:hover {
    background-color: $color-grey-235;
}

// (J) Multi-select checkboxes
.de-multi-select-dropdown .multi-select-checkbox {
    margin-left: -3px;
    // Without this, the check box's handling of events causes the 
    // dropdown to close even if we don't want it to
    pointer-events: none;
}

// Set font for the search text input field
.de-multi-select-dropdown input {
    font-family: $de-font-family;
}