////////////////////////////////////////////////////////////////////////////////
// These are for containing the split container content
////////////////////////////////////////////////////////////////////////////////
.horizontal-split-container {
    height: 100%;
}
.horizontal-split-container div[data-testid="horizontal-panel-resizer-drag-bar"] {
    // The default z-index overlaid everything (1401!)
    z-index: 1 !important;
}
