@import 'webcore-ux/style/Colors';
@import '../common/Common.scss';
@import '../common/VisualBox.scss';

.price-history {
    width: 100%;
}

.price-history .price-history-charts {
    position: relative;
    width: 100%;
    height: $app-tab-contents-height;
}

// Use this to wrap each word when viewing side tabs in Japanese 
.price-history .vertical-tab-nav-ja span.MuiTab-wrapper {
    word-spacing: 150px;
}
