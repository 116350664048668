@import 'common/Common.scss';

////////////////////////////////////////////////////////////////////////////////
// Use the styles in this sheet to create a DataGrid that fills it's vertical
// height and allows column headers to wrap.  Just place the DataGrid 
// component inside a div whose className contains de-data-grid-container.
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
// These styles ensure that the grid fills the available height
////////////////////////////////////////////////////////////////////////////////
.de-data-grid-container {
    height: 100%;
}
.de-data-grid-container > .wcux-data-grid {
    height: 100%;
}
.de-data-grid-container > .ReactTable {
    height: 100%;
}
.de-data-grid-container > .rt-table {
    height: 100%;
}

////////////////////////////////////////////////////////////////////////////////
// Allow column header text to wrap since we often have long column headers
////////////////////////////////////////////////////////////////////////////////
.de-data-grid-container .rt-thead div[role="columnheader"] {
    white-space: unset;
}

// Make sure we have ABBVoice font for the page number input
.de-data-grid-container input {
    font-family: $de-font-family;
}