@import 'webcore-ux/style/Colors';
@import './common/Common.scss';
@import './common/hitachiLogos.scss';

// Hitachi Energy branding colors
$royal-blue: rgb(9, 63, 180);
$light-blue: rgb(128, 196, 255);
$purple: rgb(179, 120, 244);
$green: rgb(47, 120, 22);
$yellow: rgb(255, 181, 0);

$jemi-primary-blue: #3366ff;

//
// Main document body style
//
html,body {
    min-width: 600px;
}

body {
    margin: 0;
    height: 100%;
    font-family: $de-font-family;
    font-size: 14px;
    line-height: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $color-text-primary;
}

// Top-level container for our app content
.de-app {
    position: relative;
    height: 100%;
}

// App licensing drawer
div.app-licensing-drawer > div.MuiPaper-root {
    width: 80%;
}

// Border between stripe and tabs
.app-tabs-border {
    margin-top: $app-tabs-border-height;
}

//
// Container for identity and currency controls at top of app
//
.app-top-controls {
    position: absolute;
    top: calc((#{$app-stripe-height} + #{$app-stripe-top-border-height} - #{$app-stripe-bottom-border-height} - #{$app-tabs-button-height}) / 2);
    right: 0;
}
// Reduce the height of currency buttons to match height of tabs for better visual appeal
.app-top-controls button {
    height: $app-tabs-button-height !important;
}
.app-currency-selection {
    float: right;
    margin-right: 10px;
}

// Adjust language icon
.app-language-icon {
    margin-top: 3px !important;
    font-size: 18px !important;
}

// Adjust settings icon
.app-language-icon {
    margin-top: 3px !important;
    font-size: 18px !important;
}

// Build identity content and "menu item"
.app-header-menu {
    float: right;
    padding-left: 10px;
    padding-right: 5px;
}
.app-header-menu:hover {
    background-color: $color-grey-235;
}
.app-header-menu span {
    display: inline-block;
    line-height: $app-tabs-button-height;
    vertical-align: middle;
}
.app-header-menu svg {
    float: right;
    margin-top: 4px;
    height: calc(#{$app-tabs-button-height} - 4px);
}
.app-header-menu-popup-content {
    margin: 5px;
}
.app-header-menu-toggle-content {
    margin: 15px;
}

// Narrow app menu
.app-header-menu-narrow {
    float: right;
    padding-right: 11px;
}
.app-header-menu-narrow > svg {
    float: right;
    margin-top: 2px;
    height: calc(#{$app-tabs-button-height} - 4px);
}
.app-main-menu-selected-item {
    background-color: $color-grey-61 !important;
}


//
// App "Menu Items"
//
.app-menu-item {
    padding: 10px 10px 10px 10px;
    min-width: 200px;
    height: 20px;
    user-select: none;
}
.app-menu-item svg {
    float: left;
    margin-top: -3px;
    margin-right: 5px;
    line-height: 24px;
}
.app-menu-item span {
    display: inline-block;
    line-height: 16px;
}
.app-menu-item:hover {
    background-color: $color-grey-235;
}

//
// Menu / Items disabled
//
.app-header-menu[disabled] {
    color: $color-grey-132;
    cursor: not-allowed;
}
.app-menu-item[disabled], .app-menu-item-disabled  {
    cursor: not-allowed;
    color: $color-grey-132 !important;
}

.wcux-mainmenu-body-section {
    .wcux-mainmenu-row.wcux-mainmenu-item.download-not-allowed-note {
        display: block;
        width: 311px !important;
        height: 60px !important;
        background-color: #3366ff;
        font-size: 11px !important;
        white-space: normal;
        text-align: center;
        color: $color-grey-235 !important;

        &:hover {
            background-color: #3366ff !important;
            border-color: #3366ff !important;
            color: $color-grey-235 !important;  
        }
    }
}

//
// Footer styles
//
.app-footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $app-footer-height;
    border-top: 1px solid $color-grey-186;
}
.app-footer .app-analysis {
    float: left;
    margin-left: 5px;
    line-height: $app-footer-height;
}
.app-footer .app-attribution {
    float: right;
    margin-right: 5px;
    line-height: $app-footer-height;
}

.app-tab-contents {
    margin: 0;
    padding: $app-tab-contents-padding $app-tab-contents-padding $app-tab-contents-padding $app-tab-contents-padding;
    height: $app-tab-contents-height;
}

//
// Load indicator
//
.load-indicator-content {
    padding: 25px;
}

//
// Buttons
//
.de-link-button {
    display: inline;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $app-primary-color;
    text-decoration: none;
    font-family: $de-font-family;
    cursor: pointer;
}

    .de-link-button:hover,
    .de-link-button:focus {
        text-decoration: underline;
    }

//
// Per-view filter buttons
//
.jemi-view-filter-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
}

.jemi-view-filter-button button {
    min-width: 36px !important;
    width: 36px;
    height: 32px !important
}
.jemi-view-filter-button .wcux-button-primary svg {
    margin-top: 6px;
}

//
// Filters
//
$jemi-filters-container-width: 362px;  //calc(320px + 20px + 20px + 2px);

.jemi-filters-container {
    padding: 10px;
    border-top: 1px solid $color-grey-235;
    width: $jemi-filters-container-width;
}

.jemi-filters-container .visual-box:nth-child(n + 2) {
    margin-top: 5px;
}

.jemi-filters-container .visual-box-header + div {
    padding: 10px;
}

.jemi-filters-container .wcux-dropdown-root:nth-child(n + 2) {
    margin-top: 5px;
}

.jemi-filters-container .de-date-picker {
    width: calc(50% - 10px);
    min-width: 149px;
}

.jemi-filters-container .button-group-filter {
    position: relative;
}

.jemi-filters-container .select-all {
    position: absolute;
    top: -34px;
    right: 24px;
    color: $color-grey-140;
    transition: color 0.125s !important;
}
    .jemi-filters-container .select-all:hover {
        color: $color-grey-70;
    }

.jemi-filters-container .select-none {
    position: absolute;
    top: -34px;
    right: 0px;
    color: $color-grey-140;
    transition: color 0.125s !important;
}
    .jemi-filters-container .select-none:hover {
        color: $color-grey-70;
    }

.jemi-filters-container .de-chart-series-picker {
    // Set height for chart series pickers inside the price filters container
    height: 112px;
}


// Hide the Caption of each content tab
.app-tab-contents {
    .de-tab-panel {
        > .spatial-awareness > .visual-box > .visual-box-header,
        > .supply-and-demand > .visual-box > .visual-box-header,
        > .price-history > .visual-box > .visual-box-header,
        > .price-forecast > .visual-box > .visual-box-header,
        > .company-financials > .visual-box > .visual-box-header,
        > .weather > .visual-box > .visual-box-header {
            height: 0px;
            border: none !important;
            overflow: hidden;
        }
    }
}

// Chart title bigger and bolder
.supply-and-demand-chart-title, .company-financials-chart-title, .price-forecast h1 {
    font-size: 16px !important;
}

.chart-source-annotation {
    display: inline-block;
    width: calc(100% - 150px);
    position: absolute;
    bottom: 0;
    margin: 0 0 5px 0;
    padding: 0 0 0 150px;
    text-align: center;
    font-size: 14px;
    color: #1f1f1f;
}

// Mui Autocomplete style overrides
.MuiAutocomplete-root.Mui-focused {
    label.Mui-focused {
        color: $jemi-primary-blue !important;
    }

    fieldset {
        border-color: $jemi-primary-blue !important;
    }
}