@import 'webcore-ux/style/Colors';

// Absolute-positioned bottom of legend
$animation-bottom: 72px;
$map-animation-width: 300px;

.map-animation {
    position: absolute;
    bottom: $animation-bottom;
    left: 15px;
    padding: 10px 10px 0 10px;
    width: $map-animation-width;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color 1s;
}

    .map-animation:hover {
        background-color: #fff;
    }

.map-theme-slider {
    margin-left: 12px;
    width: calc(#{$map-animation-width} - 20px) !important;
    color: $color-grey-140 !important;
}

.map-animation-button-panel {
    margin-bottom: 8px;
}

.map-animation-button {
    width: 10px;
    height: 10px;
    margin-left: 3px;
    color: $color-grey-140;
    transition: color 0.125s !important;
}
    .map-animation-button:hover {
        color: $color-grey-70;
    }

.map-button-play-pause {
    margin-left: 5px;
}

.map-button-previous {
}

.map-button-next {
    margin-left: 5px;
}

.map-animation-frame {
    float: right;
    margin-right: 8px;
    text-align: right;
    color: $color-grey-140;

    user-select: none;
    -ms-user-select: none; /* Internet Explorer/Edge */
}
