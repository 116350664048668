@import 'webcore-ux/style/Colors';

.recharts-tooltip-wrapper {
    z-index: 1;
}

.recharts-tooltip-label * {
    font-weight: bold;
}

text.recharts-label {
    // Stroke and fill are both needed to completely affecte the color of the labels
    // #666 matches ReCharts' label colors
    fill: #666;
    stroke: #666;
    stroke-width: 0.1;
}

span.recharts-legend-item-text {
    color: $color-grey-132 !important;
}

////////////////////////////////////////////////////////////////////////////////
// ReCharts animations can be cool.  If isAnimationActive is true, then
// the animations can cause the chart's DOM elements to be destroyed and 
// recreated as the animation occurs.  When DOM elements go away, there's 
// no guarantee that we will get onMouseLeave events.  As a result, using
// React state to save the "hovered slice" of a pie, etc. to control
// mouse highlighting and interactions can cause unwanted side effects,
// like tooltips that don't go away, or hovered appearances that don't
// go back to normal when the mouse leaves.  That's why I've opted to 
// use CSS to highlight active pie slices here.
////////////////////////////////////////////////////////////////////////////////
.recharts-pie-sector > path {
    transition: opacity .1s;
}

.recharts-pie-sector > path:hover {
    opacity: 0.75;
}