.de-date-range-picker .start-date {
    float: left;
}

.de-date-range-picker .end-date {
    float: left;
    margin-left: 20px;
}

.de-date-range-picker .date-range {
    clear: both;
    margin-top: 10px;
}
